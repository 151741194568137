

.App{
  background-color:black;
  display: flex;
  flex-direction: column;
  gap: 6rem;
  overflow: hidden;
  mix-blend-mode: overlay;
}
::-webkit-scrollbar{
  display: none;
}
.stroke-text{
  color: transparent;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: white;
}
.btn{
  background-color: wheat;
  padding: 0.5rem;
  font-weight: bold;
  border: 4px solid orange;
  transition: 30ms all;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn:hover{
  cursor: pointer;
  background-color: orange;
}

