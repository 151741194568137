.plans-container{
    margin-top: -3rem;
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
    gap: 3rem;
    position: relative;
}
.plans{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3rem;
}
.plan{
    display: flex;
   flex-direction: column;
    background-color: green;
    color: black;
    gap: 2rem;
    padding: 1.5rem;
    width: 15rem;
    padding-bottom: 9rem;
}
.plan:nth-child(2){
    background-color:yellow ;
    transform: scale(1.1);
}
.plan>:nth-child(2){
    font-size: 1rem;
    font-weight: bold;
}
.plan>:nth-child(3){
    font-size: 1rem;
    font-weight: bold;
}

.plans-header{
    display: flex;
  justify-content: space-evenly;
    font-weight: bold;
    font-size: 3rem;
    color: white;
    text-transform: uppercase;
    font-style: italic;
   
    margin-top: -5rem;
    margin-left: 11rem;
}