.Reasons{
    padding: 0 2rem;
    display: flex;
    gap: 2rem;
}
.left-r{
    flex: 1 1;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    grid-auto-rows: 1fr;
}
.left-r>img{
    object-fit: cover;
}
.left-r>:nth-child(1){
    width: 12rem;
    margin-top: 1.3rem;
    grid-row: 1/3;
    height: 28rem;
}
.left-r>:nth-child(2){
    margin-top: 1.3rem;
    width: auto;
    height: 16rem;
    grid-column: 2/4;
}
.left-r>:nth-child(3){
    width:14rem;
    grid-column: 2/3;
    height: 11rem;
    grid-row: 2;
}
.left-r>:nth-child(4){
    padding-right: 5rem;
    width:12.5rem;
    height: 11rem;
    grid-row: 2;
    grid-column: 3/4;
}
.right-r{
flex: 1 1;
text-transform: uppercase;
gap: 1rem;
display: flex;
flex-direction: column;
margin-left: -2rem;
margin-top: 1rem;
}
.right-r>span{
    font-weight: bold;
    color: orange;
}
.right-r>div{
    color: pink;
    font-size: 3rem;
    font-weight: bold;
}
.details-r{
    color: antiquewhite;
    display: flex;
    flex-direction: column;
    gap: 1rem;

}
.details-r>div{
    display: flex;
    font-size: 1rem;
    gap: 1rem;
}
.details-r>div>img{
    margin-top: 0.3rem;
}